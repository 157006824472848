<template>
  <v-container fluid>
    <v-row dense justify="space-between">
      <v-col cols="12">
        <EscolhaOpcaoSelect :inscricao="inscricao" :opcoes="processo.opcoes"/>
        <EscolhaInstrumentoMusicalSelect
            v-if="inscricao.opcao.instrumentoMusicalRequerido"
            :inscricao="inscricao"
        />
        <div v-if="qtdVideosAvaliacao > 0">
          <v-row v-for="(inscricaoVideo, index) in inscricao.inscricoesLinkVideoTHE" :key="index" dense>
              <span class="pa-2 ml-2 mt-1 v-input__prepend-outer font-weight-bold" style="min-width: 13em; max-width: 13em">
                {{ inscricaoVideo.tipoLinkVideoTHE.codigo === 'PRAT' ? `Vídeo ${inscricaoVideo.ordem-1} da Prova Prática` : 'Vídeo da Prova de Solfejo e de Leitura Rítmica' }}:
              </span>
              <v-text-field
                  v-model="inscricaoVideo.link"
                  dense
                  placeholder="Insira o link do video da Prova"
                  label="Link do vídeo *"
                  :name="'linkVideoProva' + (index + 1)"
                  :rules="[campoObrigatorioRule(inscricaoVideo.link), linkVideoTHEValido]"
                  outlined
                  validate-on-blur
              />
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import rules from "@/commons/rules";
import EscolhaOpcaoSelect from "@/components/pages/Inscricao/EscolhaOpcaoSelect";
import EscolhaInstrumentoMusicalSelect from "@/components/pages/Inscricao/EscolhaInstrumentoMusicalSelect";

export default {
  name: "InscricaoFormTHEM",

  components: {
    EscolhaOpcaoSelect,
    EscolhaInstrumentoMusicalSelect
  },

  props: {
    processo: {type: Object, required: true},
    inscricao: {type: Object, required: true}
  },

  data() {
    return {
      inscricoesLinkVideoTHE: [
        {link: null, tipoLinkVideoTHE: {codigo: 'SOLF', descricao: 'Solfejo e Leitura Rítmica'}, ordem: 1},
        {link: null, tipoLinkVideoTHE: {codigo: 'PRAT', descricao: 'Prática'}, ordem: 2}
      ],
      linkProvaPraticaAdicional: {link: null, tipoLinkVideoTHE: {codigo: 'PRAT', descricao: 'Prática'}, ordem: null}
    }
  },

  async created() {
    this.inscricao.inscricoesLinkVideoTHE = this.inscricoesLinkVideoTHE;
  },

  computed: {
    qtdVideosAvaliacao() {
      return this.inscricao.opcao.qtdVideosAvaliacao;
    }
  },

  watch: {
    inscricao: {
      handler() {
        let inscricaoTHEM = {
          inscricao: this.inscricao
        };
        this.$emit("input", inscricaoTHEM);
      },
      deep: true
    },

    'inscricao.opcao.nomeOpcao': {
      handler() {
        this.inscricao.inscricoesLinkVideoTHE.splice(2)

        if (this.inscricao.opcao.qtdVideosAvaliacao > 1) {
          for (let i = 1; i < this.inscricao.opcao.qtdVideosAvaliacao; i++) {
            let novoLink = {link: null, tipoLinkVideoTHE: {codigo: 'PRAT', descricao: 'Prática'}, ordem: i+2};
            this.inscricao.inscricoesLinkVideoTHE.push(novoLink);
          }
        }
      }
    }

  },

  methods: {
    ...rules
  }
};
</script>
