<template>
  <div>
    <v-select
        v-model="inscricao.inscricaoInstrumentoMusical.instrumentoMusical"
        :items="instrumentosMusicais"
        :rules="[campoObrigatorioRule(inscricao.inscricaoInstrumentoMusical.instrumentoMusical.oid)]"
        dense
        item-text="oid"
        label="Instrumento Musical *"
        outlined
        return-object
        validate-on-blur
    >
      <template v-slot:selection="data">
        <v-chip-group>
          <v-sheet class="mx-1" color="grey lighten-2" rounded>
            <span class="mx-2 d-flex">{{ data.item.nome }}</span>
          </v-sheet>
        </v-chip-group>
      </template>
      <template v-slot:item="data">
        <v-chip-group>
          <v-sheet class="mx-1" color="grey lighten-2" rounded>
            <span class="mx-2 d-flex">{{ data.item.nome }}</span>
          </v-sheet>
        </v-chip-group>
      </template>
    </v-select>
    <v-text-field
        v-if="possuiInstrumentoMusicalOutro"
        v-model="inscricao.inscricaoInstrumentoMusical.nomeInstrumentoMusical"
        :rules="[campoObrigatorioRule]"
        dense
        label="Nome do instrumento *"
        name="inscricaoNomeInstrumento"
        maxlength="60"
        outlined
    ></v-text-field>
  </div>
</template>

<script>
import rules from "@/commons/rules";
import instrumentoMusicalService from "@/services/instrumentoMusical.service";

export default {
  name: "EscolhaInstrumentoMusicalSelect",

  props: {
    inscricao: {type: Object, required: true}
  },

  data() {
    return {
      instrumentosMusicais: []
    }
  },

  async created() {
    await instrumentoMusicalService
        .recuperarInstrumentoMusical()
        .then(response => {
          this.instrumentosMusicais = response.data;
        })
        .catch(() => {
        })
  },

  computed: {
    possuiInstrumentoMusicalOutro() {
      return (
          !!this.inscricao.inscricaoInstrumentoMusical &&
          !!this.inscricao.inscricaoInstrumentoMusical.instrumentoMusical &&
          this.inscricao.inscricaoInstrumentoMusical.instrumentoMusical.nome === "Outro"
      );
    }
  },

  methods: {
    ...rules,
  }
};
</script>
